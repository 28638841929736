import React, { useState } from "react";
import "./sidebar.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dropdownList, isSideBarHidden, updateRedux } from "../redux/commonReducer";
import incidentImage from "../../src/assets/img/incident.svg"
import logo from "../../src/assets/img/logo.svg"
import incidentTypeimage from "../../src/assets/img/lists.svg"
import smsReportImage from "../../src/assets/img/smsreport.svg"
import settingsImage from "../../src/assets/img/settings.svg"

function Sidebar() {
  // const [dropdowns, setDropdowns] = useState([
  //   { id: 1, open: false }
  // ]);
  const sideBarHidden = useSelector(isSideBarHidden)

  const dispatch = useDispatch();
  const dropdowns = useSelector(dropdownList);

  const navigate = useNavigate()

  const [toggle, setToggle] = useState(window.location.pathname.split('/')[1])

  const toggleDropdown = (dropdownId) => {
    // setDropdowns((prevState) =>
    //   prevState.map((dropdown) =>
    //     dropdown.id === dropdownId ? { ...dropdown, open: !dropdown.open } : dropdown
    //   )
    // );
    dispatch(
      updateRedux({
        key: "dropdownList",
        value: dropdowns.map((dropdown) =>
          dropdown.id === dropdownId
            ? { ...dropdown, open: !dropdown.open }
            : dropdown
        ),
      })
    );
  };

  const showSubMenu = () => {
    return window.location.pathname === "/returned-incidents" || window.location.pathname === "/incident-request"
  }

  return (
    (
      <div className={"sidebar" + (sideBarHidden ? ' hide' : '')}>
        <div className="logo"></div>
        <div className="sidebar__links">
          <div className="sidebar__logo">
            <img src={logo} alt="" />
          </div>
          <ul>
            {/* <li
              onClick={() =>
                dispatch(
                  updateRedux({
                    key: "dropdownList",
                    value: [{ id: 1, open: false }],
                  })
                )
              }
            >
              <NavLink to="/">
                <div className="icon">
                  <img src="./assets/img/dashboard.svg" alt="" />
                  Dashboard
                </div>
              </NavLink>
            </li>
            {/* <li
              onClick={() => toggleDropdown(1)}
              className={
                dropdowns.find(
                  (dropdown) => dropdown.id === 1 && dropdown.open
                ) && "active"
              }
            >
              <div className="icon">
                <img src="./assets/img/incident.svg" alt="" />
                manage Incident
              </div>
            </li> */}
            <li>
              <NavLink
                to="/incident-request"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                // className={window.location.pathname === "/returned-incidents" ? "active" : ""}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src={incidentImage} alt="" />
                  <span>Incident Requests</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/returned-incidents"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                // className={window.location.pathname === "/returned-incidents" ? "active" : ""}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src={incidentImage} alt="" />
                  <span>Returned Incidents</span>
                </div>
              </NavLink>
            </li>
            {/* <ul class="sub-menu" style={{display: showSubMenu() ? "": "none"}}>
                <li>
                  <Link
                    to="/incident-request"
                    // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                    onClick={() =>
                      dispatch(
                        updateRedux({
                          key: "dropdownList",
                          value: [{ id: 1, open: true }],
                        })
                      )
                    }
                  >
                    <div className="icon">
                      <img src="./assets/img/report.svg" alt="" />
                      <span className={window.location.pathname === "/incident-request" ? "sub-active": ""}>Incident Requests</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/returned-incidents"
                    // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                    onClick={() =>
                      dispatch(
                        updateRedux({
                          key: "dropdownList",
                          value: [{ id: 1, open: true }],
                        })
                      )
                    }
                  >
                    <div className="icon">
                      <img src="./assets/img/report.svg" alt="" />
                      <span className={window.location.pathname === "/returned-incidents" ? "sub-active": ""}>Returned Incidents</span>
                    </div>
                  </Link>
                </li>
              </ul> */}


            {/* <li>
              <NavLink
                to="/incident-request"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src="./assets/img/incident.svg" alt="" />
                  <span>Incident Requests</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/returned-incidents"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src="./assets/img/incident.svg" alt="" />
                  <span>Returned incidents</span>
                </div>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/incident-type-lists"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src={incidentTypeimage} alt="" />
                  <span>Incident Type</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/sms_text"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src={smsReportImage} alt="" />
                  <span>SMS Text</span>
                </div>
              </NavLink>
            </li>
            <li>
              {/* <NavLink
                to="/dubai_now/notifications"
              
              // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
              // onClick={() =>
              //   dispatch(
              //     updateRedux({
              //       key: "dropdownList",
              //       value: [{ id: 1, open: true }],
              //     })
              //   )
              // }
              >
              </NavLink> */}
              <div className={"icon menu " + (toggle === 'dubai_now' ? 'toggle_on' : 'toggle_off')} onClick={() => {
                setToggle(s => (s === 'dubai_now') ? null : 'dubai_now')
                navigate('/dubai_now/notifications')
              }}>
                <img src={'assets/img/dubai_now_logo.svg'} alt="" />
                <span>Dubai Now</span>
              </div>
              {<ul className={'submenu ' + (toggle === 'dubai_now' ? 'toggle_on' : 'toggle_off')}>
                <li>
                  <NavLink
                    to="/dubai_now/notifications"
                    // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                    onClick={() =>
                      dispatch(
                        updateRedux({
                          key: "dropdownList",
                          value: [{ id: 1, open: true }],
                        })
                      )
                    }
                  >
                    <div className="icon">
                      <img src={'assets/img/notification.svg'} alt="" />
                      <span>Notifications</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dubai_now/terms"
                    // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                    onClick={() =>
                      dispatch(
                        updateRedux({
                          key: "dropdownList",
                          value: [{ id: 1, open: true }],
                        })
                      )
                    }
                  >
                    <div className="icon">
                      <img src={'assets/img/terms.svg'} alt="" />
                      <span>Terms</span>
                    </div>
                  </NavLink>
                </li>
              </ul>}
            </li>
            {/* <li>
              <NavLink
                to="/terms"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src='assets/img/terms.svg' alt="" />
                  <span>Terms</span>
                </div>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/settings"
                // onClick={() => setDropdowns((s) => [...s, { id: 1 }])}
                onClick={() =>
                  dispatch(
                    updateRedux({
                      key: "dropdownList",
                      value: [{ id: 1, open: true }],
                    })
                  )
                }
              >
                <div className="icon">
                  <img src={settingsImage} alt="" />
                  <span>Settings</span>
                </div>
              </NavLink>
            </li>
            {/* {dropdowns.find(
              (dropdown) => dropdown.id === 1 && dropdown.open
            ) && (
                <ul className="dropdown fadeLeft">

                </ul>
              )} */}
            {/* <li><NavLink to='/'>
            <div className="icon">
              <img src="./assets/img/dashboard.svg" alt="" />
              Settings
            </div>
          </NavLink></li> */}
          </ul>
        </div>
      </div>
    )
  );
}

export default Sidebar;
