import React, { useEffect } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/actionCreator';
import { useNavigate } from 'react-router-dom';
import { login_loading } from '../redux/commonReducer';
function Login() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useSelector(login_loading)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate(-1)
    }
  }, [])


  const onSubmit = (data) => {
    dispatch(
      login(data, (res) => {
        if (res) {
          navigate('/incident-request')
        }
      })
    );

  };
  return (
    <section className="login">
      <img className="logo" src="./assets/img/logo.svg" alt="" />
      <div className="container">
        <div className="row vh-100 justify-content-center">
          <div className="col-md-6 login-div">
            <div className="login__form m-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 ms-0">
                  <h3>Sign In</h3>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                </Form.Group>
                <Form.Control
                  type="username"
                  placeholder="Username"
                  autoComplete="off"
                  {...register("username", { required: true })}
                />
                {errors.username && <span className='error-msg'>This field is required</span>}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                </Form.Group>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  {...register("password", { required: true })}
                />
                {errors.password && <span className='error-msg'>This field is required</span>}
                <div className="mb-3 mt-3 text-center d-flex justify-content-center" style={{ width: 'auto' }}>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <Spinner animation="border" role="status" size="sm"></Spinner> : 'Login'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login