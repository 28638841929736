import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import Header from "../components/Header";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { deleteIncidentType, getIncidentType } from "../redux/actionCreator";
import { incidentType_lists, incidentType_lists_loading, isSideBarHidden } from "../redux/commonReducer";
import { Button, Modal } from "react-bootstrap";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

function IncidentTypeLists() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const typeLists = useSelector(incidentType_lists);
  const sideBarHidden = useSelector(isSideBarHidden)
  const loading = useSelector(incidentType_lists_loading)


  const [show, setShow] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [incidentType, setIncidentType] = useState("");
  const [webDisplay, setWebDisplay] = useState("");

  useEffect(() => {
    let formData = new FormData();
    formData.append("keyword", "");
    formData.append("show_website", "");
    dispatch(getIncidentType(formData));
  }, []);

  const showPopup = (id) => {
    setShow(true);
    setDeleteID(id);
  };
  const deleteHandler = () => {
    const id = {
      id: deleteID,
    };
    dispatch(
      deleteIncidentType(id, () => {
        let formData = new FormData();
        formData.append("incident_type", incidentType);
        formData.append("show_website", webDisplay);
        dispatch(getIncidentType(formData));
      })
    );
    setShow(false);
  };

  const onEditHandler = (ele) => {
    navigate(`/edit-incident-type/${ele._id}`);
  };

  const onSearchHandler = () => {
    let formData = new FormData();
    formData.append("keyword", incidentType);
    formData.append("show_website", webDisplay);
    dispatch(getIncidentType(formData));
  };

  const onClearHandler = () => {
    setWebDisplay("")
    setIncidentType("")
    let formData = new FormData();
    formData.append("keyword", "");
    formData.append("show_website", "");
    dispatch(getIncidentType(formData));
  }

  return (
    console.log(webDisplay),
    (
      <>
        <Modal show={show} centered onHide={() => setShow(false)}>
          <Modal.Body>Are you sure you want to delete</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              No
            </Button>
            <Button variant="primary" onClick={deleteHandler}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid pl-0">
          <div className={"row" + (sideBarHidden ? ' hide' : '')}>
            <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
              <Sidebar />
            </div>
            <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
              <div className="main-content">
                <Header />
                <div className="breadcrumbs">
                  <div className="lists">
                    <ul>
                      <li>
                        <Link>Incident Type</Link>
                      </li>
                      <li>
                        <Link to="/incident-request">
                          Home <span>/</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link>Incident Type</Link>
                      </li>
                    </ul>
                  </div>
                  <Link to="/add-incident-type">
                    <button className="btn btn-download">
                      Add Incident Type
                    </button>
                  </Link>
                </div>
                <div className="incident__search">
                  <div className="row align-items-end">
                    <div className="col-md-4">
                      <label htmlFor="">Incident Type</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter incident type"
                        value={incidentType}
                        onChange={(e) => setIncidentType(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="">Public Website Display</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={webDisplay}
                        onChange={(e) => setWebDisplay(e.target.value)}
                      >
                        <option selected value="">
                          Select
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-search"
                        onClick={() => onSearchHandler()}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => onClearHandler()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="default__table">
                  <div className="table__filter">
                    {/* <div className="entries">
                      <p>Show</p>
                      <input
                        type="number"
                        placeholder="10"
                        className="form-control"
                      />
                      <p>entries</p>
                    </div> */}
                  </div>
                  {loading ?
                  <LoadingSpinner />:
                  <Table bordered hover>
                    <thead className="thead-theme">
                      <tr>
                        <th>SL No</th>
                        <th>Incident Type (EN)</th>
                        <th>Incident Type (AR)</th>
                        <th>Public Website Display</th>
                        <th style={{ minWidth: '100px' }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeLists?.map((ele, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td style={{ minWidth: "350px" }}>
                              {ele?.incident_type}
                            </td>
                            <td style={{ minWidth: "350px" }}>
                              {ele?.incident_type_ar}
                            </td>
                            <td>{ele?.show_website == "1" ? "Yes" : "No"}</td>
                            <td>
                              <img
                                src="./assets/img/edit.svg"
                                className="pointer"
                                alt=""
                                onClick={() => onEditHandler(ele)}
                              />
                              <img
                                src="./assets/img/delete.svg"
                                className="pointer"
                                onClick={() => showPopup(ele?._id)}
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>}
                  {typeLists?.length == 0 && <h2 className="no-data">No Data Found!</h2>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default IncidentTypeLists;
