import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { insertIncidentType } from '../redux/actionCreator'
import { useForm } from 'react-hook-form'
import { isSideBarHidden } from '../redux/commonReducer'

function AddIncidentType() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sideBarHidden = useSelector(isSideBarHidden)

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        const row = {
            "incident_type": data?.Incident_type_en,
            "incident_type_ar": data?.Incident_type_ar,
            "show_website": data?.display ? 1 : 0
        }
        dispatch(insertIncidentType(row, () => {
            navigate('/incident-type-lists')
        }))
        reset()
    };
    return (
        <div>
            <div className="container-fluid pl-0">
                <div className={"row" + (sideBarHidden ? ' hide' : '')}>
                    <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
                        <Sidebar />
                    </div>
                    <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
                        <div className="main-content">
                            <Header />
                            <div className="breadcrumbs">
                                <div className="lists">
                                    <ul>
                                        <li>
                                            <Link>Add Incident Type</Link>
                                        </li>
                                        <li>
                                            <Link to="/incident-request">
                                                Home <span>/</span>{" "}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>Add Incident Type</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="incident__search" style={{ backgroundColor: "#fff", border: "none" }}>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row align-items-start">
                                        <div className="col-md-5">
                                            <label htmlFor="">Incident Type English</label>
                                            <input type="text" className="form-control"  {...register("Incident_type_en", { required: true })} placeholder="Enter Incident Type" />
                                            {errors.Incident_type_en && <span className='error-msg'>Please fill out this field.</span>}
                                        </div>
                                        <div className="col-md-5">
                                            <label htmlFor="">Incident Type Arabic</label>
                                            <input type="text" className="form-control" {...register("Incident_type_ar", { required: true })} placeholder="Enter Incident Type" />
                                            {errors.Incident_type_ar && <span className='error-msg'>Please fill out this field.</span>}
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <input className="form-check-input" type="checkbox" {...register("display")} value="1" id="check" />
                                            <label htmlFor="check" style={{ marginLeft: "10px", fontSize: "16px" }}>Display in Public facing website</label>
                                        </div>
                                        <div className="col-md-2 mt-4 d-flex align-items-center">
                                            <button className="btn btn-search" type='submit'>Submit</button>
                                            <Link to='/incident-type-lists'><button className="btn btn-danger">Cancel</button></Link>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddIncidentType
