import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss'
import { HashRouter, BrowserRouter } from 'react-router-dom';
import RouteHandler from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <RouteHandler />
      </BrowserRouter>
    </div>
  );
}

export default App;
