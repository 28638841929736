import { useState } from 'react'
import './App.scss'
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import IncidentRequest from './pages/IncidentRequest';
import IncidentReturnedRequest from './pages/IncidentReturnedRequest';
import IncidentTypeLists from './pages/IncidentTypeLists';
import PrivateRoutes from './utils/PrivateRoutes';
import AddIncidentType from './pages/AddIncidentType';
import EditIncidentType from './pages/EditIncidentType';
import SmsText from './pages/SmsText';
import Settings from './pages/Settings';
import Terms from './pages/Terms';
import Dubai_Now_Notifications from './pages/Dubai_Now_Notifications';

function RouteHandler() {
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<IncidentRequest />} />
          <Route path='/incident-request' element={<IncidentRequest />} />
          <Route path='/returned-incidents' element={<IncidentReturnedRequest />} />
          <Route path='/incident-type-lists' element={<IncidentTypeLists />} />
          <Route path='/add-incident-type' element={<AddIncidentType />} />
          <Route path='/edit-incident-type/:id' element={<EditIncidentType />} />
          <Route path='/sms_text' element={<SmsText />} />
          <Route path='/dubai_now/notifications' element={<Dubai_Now_Notifications />} />
          <Route path='/dubai_now/terms' element={<Terms />} />
          <Route path='/settings' element={<Settings />} />
          {/* <Route path='/terms' element={<Terms />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export default RouteHandler
