import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { returningToCustomer, uploadReturnedIncidents } from "../redux/actionCreator";
import { isSideBarHidden } from "../redux/commonReducer";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

function Settings() {
  const dispatch = useDispatch();
  const sideBarHidden = useSelector(isSideBarHidden);

  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState(null)
  const [loader1, setLoader1] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [err, setErr] = useState('')

  function uploadButtonHandler() {
    setShowModal(true)
  }

  function fileUploadHandler(e) {
    setErr('')
    // if (e.target.files[0].type === "application/x-zip-compressed") {
    //   setFile(e.target.files)
    // } else {
    //   setErr('File type not supported')
    // }
    setFile(e.target.files)
  }

  function cancelHandler() {
    setErr('')
    setFile(null)
    setShowModal(false)
  }

  function uploadSubmitHandler() {
    setErr('')
    if (file) {
      setLoader2(true)
      let formdata = new FormData()
      formdata.append('incident_files', file[0])
      dispatch(uploadReturnedIncidents(formdata, (status, msg) => {
        status && setShowModal(false)
        setLoader2(false)
        setFile(null)
      }))
    } else {
      setErr('No file selected')
    }
  }

  function syncHandler() {
    setLoader1(true)
    dispatch(returningToCustomer(() => {
      setLoader1(false)
    }))
  }

  return (
    <>
      <div className="container-fluid pl-0">
        <div className={"row" + (sideBarHidden ? " hide" : "")}>
          <div className={"col right-sidebar" + (sideBarHidden ? " hide" : "")}>
            <Sidebar />
          </div>
          <div className={sideBarHidden ? "col-md-12  px-5" : "col-md-9"}>
            <div className="main-content">
              <Header />
              <div className="breadcrumbs">
                <div className="lists">
                  <ul>
                    <li>
                      <Link>Settings</Link>
                    </li>
                    <li>
                      <Link to="/incident-request">
                        Home <span>/</span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link>Settings</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="settings pt-3">
                <div>
                  <div className="header">Upload Returned Incidents</div>
                  <div className="content">
                    <button onClick={uploadButtonHandler} >
                      Upload
                      <img src="/assets/img/upload.svg" alt="" />
                    </button></div>
                </div>
                <div>
                  <div className="header">Sync Returned Incidents</div>
                  <div className="content">
                    <button onClick={syncHandler} disabled={loader1}>
                      Sync
                      {loader1
                        ? <img src="/assets/img/loader.svg" alt="" />
                        : <img src="/assets/img/sync-svgrepo-com.svg" alt="" />}
                    </button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={cancelHandler} centered>
          <div className="upload-returned-incidents">
            <h3>Upload zip file</h3>
            <div className="label">
              {
                file ?
                  <label htmlFor="">
                    <img className="zip" src="/assets/img/zipIcon.svg" alt="" />
                    <p>{file[0].name}</p>
                  </label>
                  : <label htmlFor="upload-returned-incidents">
                    <img src="/assets/img/upload.svg" alt="" />
                    {err && <small>{err}</small>}
                  </label>
              }
            </div>
            <input type="file" id="upload-returned-incidents" hidden accept=".zip" onChange={fileUploadHandler} />
            <div className="buttons">
              <button onClick={cancelHandler}>Cancel</button>
              <button onClick={uploadSubmitHandler} disabled={loader2}>
                Confirm
                {
                  loader2
                    ? <img src="/assets/img/loader.svg" alt="" />
                    : null
                }
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Settings;
