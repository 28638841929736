import React from 'react'
import Sidebar from '../components/Sidebar'
import { useSelector } from 'react-redux'
import { isSideBarHidden } from '../redux/commonReducer'

function Dashboard() {
    const sideBarHidden = useSelector(isSideBarHidden)

    return (
        <>
            <div className="container-fluid pl-0">
                <div className={"row" + (sideBarHidden ? ' hide' : '')}>
                    <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
                        <Sidebar />
                    </div>
                    <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
                        <div className="main-content">
                            <h1>Dashboard</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard