import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import { isSideBarHidden } from '../redux/commonReducer'
import { useDispatch, useSelector } from 'react-redux'
import Button1 from '../components/Button1/Button1'
import { editorConfig, editorConfigAra } from '../constants/configuration'
import JoditReact from "jodit-react";
import { addTerms, getTerms } from '../redux/actionCreator'

export default function Terms() {
    const sideBarHidden = useSelector(isSideBarHidden)

    const dispatch = useDispatch()

    const [inputs, setInputs] = useState({
        contentEnglish: '',
        contentArabic: ''
    });

    const [terms, setTerms] = useState({})

    const inputChangeHandler = (field, value) => {
        setInputs(prevState => ({ ...prevState, [field]: value }));
    };

    useEffect(() => {
        dispatch(getTerms((res) => {
            setTerms(res)
            setInputs({
                contentEnglish: res?.incident_intro_en,
                contentArabic: res?.incident_intro_ar,
            })
        }))
    }, [])

    const submitHandler = () => {
        const data = {
            // title_en: 'Terms and conditions',
            // title_ar: 'Terms and conditions',
            incident_intro_en: inputs?.contentEnglish,
            incident_intro_ar: inputs?.contentArabic,
            id: terms?._id ? terms?._id : ""
        }
        dispatch(addTerms(data))
    }
    return (
        <div className="container-fluid pl-0">
            <div className={"row" + (sideBarHidden ? ' hide' : '')}>
                <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
                    <Sidebar />
                </div>
                <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
                    <div className="main-content">
                        <Header />
                        <div className="breadcrumbs">
                            <div className="lists">
                                <ul>
                                    <li>
                                        <Link>Terms and conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/incident-request">
                                            Home <span>/</span>{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dubai_now/notifications">
                                            Dubai Now <span>/</span>{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>Terms and conditions</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <section className='terms__privacy mb-3'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="contentEnglish">Content English</label>
                                                <JoditReact
                                                    config={editorConfig}
                                                    value={inputs?.contentEnglish}
                                                    onChange={(newContent) => inputChangeHandler('contentEnglish', newContent)}
                                                    className="english"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="contentArabic">Content Arabic</label>
                                                <JoditReact
                                                    config={editorConfigAra}
                                                    value={inputs?.contentArabic}
                                                    onChange={(newContent) => inputChangeHandler('contentArabic', newContent)}
                                                    className="arabic"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                        <div className='buttons d-flex gap-3'>
                            <Button1 Text={'Update'} onClick={() => submitHandler()} />
                            <Button1 Text={'Cancel'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
